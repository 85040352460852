<template>
  <div class="audit">
    <div class="not-found">
      <img src="./img/notfound.png" alt="" />
    </div>
    <div class="audit-info">
      <div class="tips">您已提交发布申请，请耐心等待平台审核</div>
      <div
        class="back-btn"
        @click="$router.replace({ name: 'convenientService' })"
      >
        返回
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "audit",
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.audit {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  padding-top: 192px;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
  .not-found {
    width: 354px;
    height: 272px;
    margin-bottom: 170px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .audit-info {
    .tips {
      text-align: center;
      font-size: 26px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 36px;
      margin-bottom: 32px;
    }
    .back-btn {
      width: 550px;
      height: 70px;
      background: #3781ff;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #ffffff;
    }
  }
}
</style>
